$loader-size: 16px;
$loader-offset: 30px;
$loader-duration: 2s;
$loader-ease: ease;
$loader-color: #FFF;

.loader {
  width: $loader-size;
  height: $loader-size;
  box-shadow: 0 $loader-offset, 0 -$loader-offset;
  border-radius: 4px;
  background: currentColor;
  display: block;
  margin: -50px auto 0;
  position: relative;
  color: $loader-color;
  transform: translateY($loader-offset);
  box-sizing: border-box;
  animation: animloader $loader-duration $loader-ease infinite;

  &::after,
  &::before {
    content: '';  
    box-sizing: border-box;
    width: $loader-size;
    height: $loader-size;
    box-shadow: 0 $loader-offset, 0 -$loader-offset;
    border-radius: 4px;
    background: currentColor;
    color: $loader-color;
    position: absolute;
    left: $loader-offset;
    top: 0;
    animation: animloader $loader-duration 0.2s $loader-ease infinite;
  }

  &::before {
    animation-delay: 0.4s;
    left: 60px;
  }
}

@keyframes animloader {
  0% {
    top: 0;
    color: white;
  }
  50% {
    top: 30px;
    color: rgba(255, 255, 255, 0.2);
  }
  100% {
    top: 0;
    color: white;
  }
}
