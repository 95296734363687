@import '../../utils/_variables.scss';

.mainContainer {
  width: 100vw;
  // background: ;
  height: 100vh;
  overflow: hidden;
  display: flex;

  .leftSide {
    height: 100vh;
    width: 60vw;
    display: flex;
    flex-direction: column;
    // background: $PRIMARY_COLOR;
    
    .heading {
      color: $COLOR_WHITE_1;
      text-align: left;
      margin: 20px 0 0 20px;
      font-size: 40px;
      display: flex;
      flex-direction: column;
      line-height: 1.2;

      span {
        font-weight: 900;
      }
    }

    .responsive {
      width: 100%;
      height: 100%;
      margin: auto;
    }
  }

  .rightSide {
    width: 40%;
    height: 100vh;
    display: flex;
    overflow: auto;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #dfedfa;
    form{
      display: flex;
      gap: 6px;
      flex-direction: column;
      width: 60%;
      text-align: justify;
    }
  }
}
