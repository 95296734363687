$COLOR_BLACK_1: #000;
$COLOR_BLACK_2: #1a1a1a;

$COLOR_GRAY_1: #bebbbb;
$COLOR_GRAY_2: #848484;
$COLOR_GRAY_3: #f2f2f2;
$COLOR_GRAY_4: #74768f;
$COLOR_GRAY_5: #f0f0f0;
$COLOR_GRAY_6: #c1c1c1;
$COLOR_GRAY_7: #cdc3c3;
$COLOR_GRAY_8: #6881f622;
$COLOR_GRAY_9: #8a7777;

$COLOR_WHITE_1: #fff;

$COLOR_MAIN_BLUE:#4065B5;
$COLOR_SUBMAIN_BLUE: #3A57A9;
$COLOR_BLUE_1: #618eff;
$COLOR_BLUE_2: #7c60e2;
$COLOR_BLUE_3: #782ead;
$COLOR_BLUE_4: #590977;
$COLOR_BLUE_5: #6c8efb;
$COLOR_BLUE_6: #6880f61a;
$COLOR_BLUE_7: #697ef5;
$COLOR_BLUE_8: #6d77f0;

$COLOR_RED_1: #ff0000;
$COLOR_RED_2: rgb(181, 2, 2);

$PRIMARY_COLOR: $COLOR_MAIN_BLUE;

$FONT_SIZE_10: 10px;
$FONT_SIZE_11: 11px;
$FONT_SIZE_12: 12px;
$FONT_SIZE_13: 13px;
$FONT_SIZE_14: 14px;
$FONT_SIZE_15: 15px;
$FONT_SIZE_16: 16px;
$FONT_SIZE_17: 17px;
$FONT_SIZE_18: 18px;
$FONT_SIZE_20: 20px;

$transition-time: 400ms;
