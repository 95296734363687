@import "../../utils/_variables.scss";

.buttonContainer {
  background: $PRIMARY_COLOR !important;
  height: 30px;
  text-transform: none !important;
  font-size: $FONT_SIZE_14 !important;

  &:hover {
    background: #5FC2D8 !important;
  }
}


.CopyButton{
  text-transform: none !important;
  text-decoration: none;
}