body{
    // font-family: 'Poppins';
    margin: 0;
    padding: 0;
    font-family: 'Inter', Arial, sans-serif;

}
p,h1,h2,h3,h4,h5,h6{
    margin: 0;
    font-family: 'Inter', Arial, sans-serif;

}