// @import url("http://fonts.googleapis.com/css?family=Noto+Sans:400,500,600,700,400italic,700italic&subset=latin,latin-ext");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import "utils/_variables.scss";
body {
  overflow: hidden;
  .app {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    font-family: 'Inter', Arial, sans-serif;

    ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    ::-webkit-scrollbar-track {
      background: $COLOR_GRAY_5;
    }

    ::-webkit-scrollbar-thumb {
      background-color: $COLOR_GRAY_6;
      border-radius: 20px;
      border: 2px solid $COLOR_GRAY_5;
    }
  }
  div, p, h1, h2, h3, h6, span, button, input, select {
    font-family: 'Inter', Arial, sans-serif;
  }
}

div, p, h1, h2, h3, h6, span, button, input, select {
  font-family: 'Inter', Arial, sans-serif;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}